<template>
  <div>
    <CoolLightBox :items="itemsLocal" :index="index" loop @close="index = null">
      <template #icon-previous>
        <div class="icon-con">
          <feather-icon icon="ArrowLeftCircleIcon" class="icon" />
        </div>
      </template>
      <template #icon-next>
        <div class="icon-con">
          <feather-icon icon="ArrowRightCircleIcon" class="icon icon-next" />
        </div>
      </template>
      <template #close>
        <feather-icon icon="XIcon" />
      </template>
    </CoolLightBox>

    <b-row>
      <b-col
        md="4"
        v-for="(image, imageIndex) in itemsLocal"
        :key="imageIndex"
        @click="index = imageIndex"
        class="mb-1"
      >
        <div class="photo-con">
          <img
            :src="image"
            alt="service photo"
            class="img-fluid photo cursor-pointer"
          />
          <!-- delete btn -->
          <div class="icons-con">
            <button
              class="
                btn btn-danger
                icon-con
                p-0
                d-flex
                justify-content-center
                align-items-center
              "
              v-b-modal.modal-center-del
              @click="getDelPhotoId(items.photos[imageIndex].id)"
            >
              <feather-icon icon="TrashIcon" class="text-white" />
            </button>
          </div>
        </div>
      </b-col>
      <b-col md="4" class="mb-1">
        <div class="mb-1">
          <MyPhotoUpload @getPhotoId="uploadPhoto" />
        </div>
        <!-- Update Photo -->
        <div v-if="showAddBtn">
          <b-button
            size="sm"
            variant="primary"
            @click="$emit('updatePhotoFromChild')"
          >
            <b-spinner
              variant="white"
              v-if="spinner === true"
              label="Text Centered"
            ></b-spinner>
            <span v-else>Add Photo</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <!--Edit_Del_Modal-->
    <div>
      <b-modal
        v-model="delModal"
        id="modal-center-del"
        size="sm"
        header-class="text-uppercase"
        centered
        hide-footer
      >
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div
            class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            "
          >
            <h5 class="mb-0">Are you sure to delete?</h5>
            <b-button variant="outline-danger" @click="delModal = false">
              X
            </b-button>
          </div>
        </template>

        <div>
          <h4 class="text-center mb-2">Delete this photo?</h4>
          <div class="d-flex justify-content-center">
            <!-- cancel btn -->
            <b-button
              pill
              variant="primary"
              @click="delModal = false"
              class="mr-1"
            >
              <span class="text-nowrap">Cancel</span>
            </b-button>

            <!-- delete btn -->
            <b-button pill variant="danger" @click="delPhoto">
              <b-spinner
                variant="white"
                v-if="spinner === true"
                label="Text Centered"
              ></b-spinner>
              <span v-else>Delete</span>
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
    <!--edit_del_Modal-->
  </div>
</template>
      
<script>
import store from "@/store";
import { ref } from "@vue/composition-api";
import { BRow, BCol, BButton, BModal, BSpinner, } from "bootstrap-vue";
import MyPhotoUpload from "./MyPhotoUpload.vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BSpinner,
    MyPhotoUpload,
  },
  props: {
    items: {
      type: Object,
      default: () => {},
    },
    postForm: {
      type: Object,
      default: () => {},
    },
    myModal: {
      type: Boolean,
    },
    postFormEditDatas: {
      type: Function,
    },
    fetchDetails: {
      type: Function,
    },
    urlName: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const index = ref(null);
    const itemsLocal = ref([]);
    const itemPhotosIdArr = ref([]);
    const delPhotoId = ref("");
    const showAddBtn = ref(false);
    const delModal = ref(false);
    const spinner = ref(false);

    for (let i = 0; i < props.items.photos.length; i++) {
      itemsLocal.value = [...itemsLocal.value, props.items.photos[i].name];
      itemPhotosIdArr.value = [
        ...itemPhotosIdArr.value,
        props.items.photos[i].id,
      ];
    }

    const getDelPhotoId = (id) => {
      delPhotoId.value = id;
    };

    const uploadPhoto = (photoIds) => {
      showAddBtn.value = true;
      itemPhotosIdArr.value = [...itemPhotosIdArr.value, photoIds];
      let multiplePhotos = itemPhotosIdArr.value;
      let willUpdatePhotos = JSON.stringify(multiplePhotos);
      emit("getUpdatePhotoId", willUpdatePhotos);
    };

    const delPhoto = () => {
      const delPhotoArr = itemPhotosIdArr.value.filter(
        (id) => id !== delPhotoId.value
      );
      const delPhotoJson = JSON.stringify(delPhotoArr);
      props.postForm.photos = delPhotoJson;
      spinner.value = true;
      store
        .dispatch("app-mypages/delData", {
          path: "photo",
          id: delPhotoId.value,
        })
        .then((response) => {
          props.postFormEditDatas();
          store
            .dispatch("app-mypages/updatePhotoArr", {
              path: `${props.urlName}/${props.items.id}`,
              payload: props.postForm,
            })
            .then((response) => {
              props.fetchDetails();
              props.myModal = false;
              spinner.value = false;
              delModal.value = false;
              index.value = null;
            });
        });
    };
    return {
      props,
      itemsLocal,
      index,
      showAddBtn,
      spinner,
      getDelPhotoId,
      uploadPhoto,
      delModal,
      delPhoto,
    };
  },
};
</script>
  
<style scoped>
.photo-con {
  height: 200px;
  position: relative;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.2s;
}

.photo:hover {
  opacity: 0.7;
}

.icons-con {
  position: absolute;
  top: 0;
  right: 0;
}

.icon-con {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}

.icon {
  width: 50px;
  height: 50px;
}

.icon-next {
  position: absolute;
  right: 35px;
}
</style>