<template>
  <div>
    <!-- Description Modal-->
    <div>
      <b-modal
        v-model="desModal"
        id="modal-scrollable-des"
        class="modal-scrollable"
        size="lg"
        header-class="text-uppercase"
        scrollable
        hide-footer
      >
        <template #modal-header>
          <div
            class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            "
          >
            <h5 class="mb-0">Description</h5>
            <b-button variant="outline-danger" @click="desModal = false">
              X
            </b-button>
          </div>
        </template>

        <div class="d-flex align-items-center">
          <span v-html="desData.description" class="des-con"></span>
        </div>
      </b-modal>
    </div>
    <!--Description Modal-->
  </div>
</template>
    
  <script>
import store from "@/store";
import { ref } from "@vue/composition-api";
import { BButton, BSpinner, BRow, BCol, BModal } from "bootstrap-vue";

export default {
  components: {
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
  },
  props: {
    desData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const spinner = ref(false);
    const desModal = ref(false);

    return {
      spinner,
      desModal,
    };
  },
};
</script>