// Assuming you have included the Moment.js library in your project
import moment from 'moment';

// Convert 24-hour time to 12-hour format with AM/PM
export default function convertTo12HourFormat(time) {
  var momentTime = moment(time, "HH:mm");
  return momentTime.format("h:mm A");
}

// Example usage
// var time24Hour = "18:30";
// var time12Hour = convertTo12HourFormat(time24Hour);
// console.log(time12Hour); // Output: "6:30 PM"
