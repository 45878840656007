<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 py-3 px-5 card-company-table">
        <div v-for="s in serviceDetails" :key="s.id" class="mb-5">
          <!-- id, title, sub_title -->
          <b-row class="mb-3">
            <!-- id -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">#</h4>
              <h4 class="font-weight-normal">{{ s.id }}</h4>
            </b-col>
            <!-- actions -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                Update ({{ serviceDetails[0].title }})
              </h4>
              <div>
                <!-- edit btn -->
                <!-- <b-button pill size="sm" variant="primary" @click="editServiceData" class="mr-1"
                  v-b-modal.modal-scrollable-actions>
                  <span class="text-nowrap">Update</span>
                </b-button> -->
                <router-link :to="{
                  name: `add-edit-services`,
                  query: {
                    isEdit: true,
                    editDatas: JSON.stringify(serviceDetails[0]),
                    categories: JSON.stringify(categories),
                    states: JSON.stringify(states),
                  },
                }" class="dropdown-item-con">
                  <b-button pill variant="primary" class="mr-1">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Update</span>
                  </b-button>
                </router-link>

                <!-- delete btn -->
                <!-- <b-button
                  pill
                  size="sm"
                  variant="danger"
                  @click="isAllEdit = false"
                  v-b-modal.modal-scrollable-actions
                >
                  <span class="text-nowrap">Delete</span>
                </b-button> -->
              </div>
            </b-col>

            <!-- title -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">title :</h4>
              <h4 class="font-weight-normal">{{ s.title }}</h4>
            </b-col>
            <!-- sub_title -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">sub title :</h4>
              <h4 class="font-weight-normal">{{ s.sub_title }}</h4>
            </b-col>

            <!-- category -->
            <!-- <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">category :</h4>
              <h4 class="font-weight-normal">{{ s.category.name }}</h4>
            </b-col> -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">categories :</h4>
              <template v-if="s.categories.length !== 0">
                <h4 class="font-weight-normal" v-for="c in s.categories" :key="c.id">{{ c.name }},</h4>
              </template>
            </b-col>
            <!-- booking_mode -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">booking mode :</h4>
              <h4 class="font-weight-normal">
                {{ s.booking_mode === 1 ? "true" : null }}
              </h4>
              <h4 class="font-weight-normal">
                {{ s.booking_mode === 0 ? "false" : null }}
              </h4>
            </b-col>

            <!-- description, state -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                description :
              </h4>
              <!-- <h4 class="font-weight-normal" v-html="e.description"></h4> -->

              <b-button @click="desDetailsData(serviceDetails[0])" pill size="sm" variant="primary"
                v-b-modal.modal-scrollable-des>
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>

            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                state :
              </h4>
              <h4 class="font-weight-normal">{{ s.state.name }}</h4>
            </b-col>
            <!-- contact_info -->
            <b-col md="12" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">contact info :</h4>
              <h4 class="font-weight-normal">{{ s.contact_info }}</h4>
            </b-col>

            <!-- email -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">email :</h4>
              <h4 class="font-weight-normal">{{ s.email }}</h4>
            </b-col>
            <!-- phone -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">phone :</h4>
              <h4 class="font-weight-normal">{{ s.phone }}</h4>
            </b-col>

            <!-- opening_time -->
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">Opening Time :</h4>
              <h4 class="font-weight-normal">{{ convertTo12HourFormat(s.opening_time) }}</h4>
            </b-col>
            <!-- closing_time -->
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">Closing Time :</h4>
              <h4 class="font-weight-normal">{{ convertTo12HourFormat(s.closing_time) }}</h4>
            </b-col>
          </b-row>

          <!-- menus , cupons, reviews & bookings -->
          <b-row>
            <!-- menus -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">menus :</h4>
              <b-button pill size="sm" variant="primary" @click="() => {
                isMenu = true;
                isCupon = false;
                isReview = false;
                isBooking = false;
                isPhoto = false;
              }
                " v-b-modal.modal-scrollable class="d-block">
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>
            <!-- cupons -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">cupons :</h4>
              <b-button pill size="sm" variant="primary" @click="() => {
                isMenu = false;
                isCupon = true;
                isReview = false;
                isBooking = false;
                isPhoto = false;
              }
                " v-b-modal.modal-scrollable class="d-block">
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>
            <!-- reviews -->
            <b-col md="6" class="mb-3">
              <h4 class="font-weight-bolder text-uppercase text-underline">reviews :</h4>
              <b-button pill size="sm" variant="primary" @click="() => {
                isMenu = false;
                isCupon = false;
                isReview = true;
                isBooking = false;
                isPhoto = false;
              }
                " v-b-modal.modal-scrollable class="d-block">
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>
            <!-- bookings -->
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">bookings :</h4>
              <b-button pill size="sm" variant="primary" @click="() => {
                isMenu = false;
                isCupon = false;
                isReview = false;
                isBooking = true;
                isPhoto = false;
              }
                " v-b-modal.modal-scrollable class="d-block">
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>
            <!-- photos -->
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">photos :</h4>
              <b-button pill size="sm" variant="primary" @click="() => {
                isMenu = false;
                isCupon = false;
                isReview = false;
                isBooking = false;
                isPhoto = true;
              }
                " v-b-modal.modal-scrollable class="d-block">
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>
          </b-row>
        </div>

        <div class="w-100 text-center">
          <b-button type="button" variant="primary" @click="() => router.go(-1)" class="col-md-10 col-lg-6">
            <!-- <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner> -->
            <span>Back</span>
          </b-button>
        </div>
      </b-card>
    </b-card>

    <!--Model-->
    <div>
      <b-modal v-model="myModal" id="modal-scrollable" class="modal-scrollable" size="lg" header-class="text-uppercase"
        scrollable hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h5 class="mb-0" v-if="isMenu">Menus</h5>
            <h5 class="mb-0" v-if="isCupon">Cupons</h5>
            <h5 class="mb-0" v-if="isReview">Reviews</h5>
            <h5 class="mb-0" v-if="isBooking">Bookings</h5>
            <h5 class="mb-0" v-if="isPhoto">Photos</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>
        <b-tabs fill lazy v-if="!isPhoto">
          <b-tab title="DETAILS" :active="!isEdit">
            <!-- Services Details Table -->
            <Table :items="isMenu
              ? serviceDetails[0].menus
              : isCupon
                ? serviceDetails[0].cupons
                : isReview
                  ? serviceDetails[0].reviews
                  : isBooking
                    ? bookings
                    : ''
              " :fields="isMenu
    ? menuFields
    : isCupon
      ? cuponFields
      : isReview
        ? reviewFields
        : isBooking
          ? bookingFields
          : ''
    " :isMenu="isMenu" :isCupon="isCupon" :isReview="isReview" @editFunc="editData"
              @bookingEditFunc="pendingBookingData" @delFunc="delFunc" @desDetail="desDetailsData" />
          </b-tab>

          <!-- Add & Edit tab -->
          <b-tab :active="isEdit" :title="isEdit ? 'EDIT' : 'ADD NEW'" v-if="isMenu || isCupon || isEdit">
            <form ref="form" @submit.prevent="">
              <b-form-group>
                <b-row>
                  <!-- Code -->
                  <b-col v-if="isCupon">
                    <div class="mb-2">
                      <label for="code" class="font-weight-bolder">Code :</label>
                      <b-form-input v-model="cuponPostDatas.code" id="code" placeholder="Enter Cupon Code" required>
                      </b-form-input>
                    </div>
                  </b-col>
                  <!-- Menu Name -->
                  <b-col v-if="isMenu">
                    <div class="mb-2">
                      <label for="menuTitle" class="font-weight-bolder">Name :</label>
                      <b-form-input v-model="menuPostDatas.name" id="menuTitle" placeholder="Enter Menu Name" required>
                      </b-form-input>
                    </div>
                  </b-col>
                  <!-- Cupon Name -->
                  <b-col v-if="isCupon">
                    <div class="mb-2">
                      <label for="cuponTitle" class="font-weight-bolder">Name :</label>
                      <b-form-input v-model="cuponPostDatas.name" id="cuponTitle" placeholder="Enter Cupon Name" required>
                      </b-form-input>
                    </div>
                  </b-col>
                  <!-- Menu Subtitle -->
                  <b-col v-if="isMenu">
                    <div class="mb-2">
                      <label for="menuSubtitle" class="font-weight-bolder">Sub Title :</label>
                      <b-form-input v-model="menuPostDatas.subtitle" id="menuSubtitle" placeholder="Enter Menu Subtitle"
                        required>
                      </b-form-input>
                    </div>
                  </b-col>
                  <!-- Rating -->
                  <b-col v-if="isReview" sm="12">
                    <div class="mb-2">
                      <label for="rating" class="font-weight-bolder">Rating :</label>
                      <b-form-rating v-model="reviewPostDatas.rating" variant="primary" required></b-form-rating>
                    </div>
                  </b-col>
                  <!-- Comment -->
                  <b-col v-if="isReview" sm="12">
                    <div class="mb-2">
                      <label for="comment" class="font-weight-bolder">Comment :</label>
                      <b-form-textarea id="comment" v-model="reviewPostDatas.comment" placeholder="Enter Comment"
                        rows="6"></b-form-textarea>
                    </div>
                  </b-col>
                </b-row>
                <!-- Price -->
                <template v-if="isMenu">
                  <div class="mb-2">
                    <label for="price" class="font-weight-bolder">Price :</label>
                    <b-form-input v-model="menuPostDatas.price" id="price" placeholder="Enter Menu Price" required>
                    </b-form-input>
                  </div>
                </template>
                <!-- Description -->
                <div v-if="isMenu" class="mb-2">
                  <label for="menuDes" class="font-weight-bolder">Description :</label>
                  <vue-editor v-model="menuPostDatas.description"></vue-editor>
                </div>
                <!-- Description -->
                <div v-if="isCupon" class="mb-2">
                  <label for="cuponDes" class="font-weight-bolder">Description :</label>
                  <vue-editor v-model="cuponPostDatas.description"></vue-editor>
                </div>
                <!-- Photos -->
                <div v-if="isMenu" class="mb-2">
                  <MyPhotoSingleUpload @getPhotoId="uploadPhoto" :isEdit="isEdit" :editPhotoObj="photoObj" />
                </div>
                <!-- Submit Btn -->
                <div class="row justify-content-center px-2">
                  <!-- Cancel btn -->
                  <b-button v-if="isEdit" variant="outline-primary" @click="handleCancel" class="col-5 mr-2">
                    <!-- <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner> -->
                    <span>Cancel</span>
                  </b-button>

                  <!-- Menu Submit Btn & Update Btn -->
                  <b-button v-if="isMenu && !isEdit" variant="primary" type="button"
                    @click="handleSubmit('menus', menuPostDatas)" class="col-5">
                    <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                    <span v-else>Submit New Menu</span>
                  </b-button>

                  <b-button v-if="isMenu && isEdit" variant="primary" type="button"
                    @click="updateData('menus', menuPostDatas)" class="col-6">
                    <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                    <span v-else>Update Menu</span>
                  </b-button>
                  <!-- Cupon Submit Btn & Update Btn -->
                  <b-button v-if="isCupon && !isEdit" variant="primary" type="button"
                    @click="handleSubmit('cupons', cuponPostDatas)" class="col-6">
                    <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                    <span v-else>Submit</span>
                  </b-button>

                  <b-button v-if="isCupon && isEdit" variant="primary" type="button"
                    @click="updateData('cupons', cuponPostDatas)" class="col-6">
                    <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                    <span v-else>Update Cupon</span>
                  </b-button>

                  <!-- Review Update Btn -->
                  <b-button v-if="isReview && isEdit" variant="primary" type="button"
                    @click="updateData('reviews', reviewPostDatas)" class="col-6">
                    <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                    <span v-else>Update Review</span>
                  </b-button>
                </div>
              </b-form-group>
            </form>
          </b-tab>
        </b-tabs>

        <template v-if="isPhoto">
          <MyLightBox :items="serviceDetails[0]" @getUpdatePhotoId="uploadUpdatePhoto" @updatePhotoFromChild="updatePhoto"
            :postFormEditDatas="editOriVals" :fetchDetails="fetchServiceDetails" :postForm="postForm" :myModal="myModal"
            urlName="services" />
        </template>
      </b-modal>
    </div>
    <!--Model-->

    <!-- Bookings Approve or Decline Modal-->
    <div>
      <b-modal v-model="pendingModal" id="modal-center-pending" centered header-class="text-uppercase justify-content-end"
        hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="">
            <!-- <h5 class="mb-0">Tickets</h5> -->
            <b-button variant="outline-danger" @click="pendingModal = false" class="float-right">
              X
            </b-button>
          </div>
        </template>
        <!-- Bookings Pending -->
        <h4 class="text-center mb-2" v-if="bookingPostData.booking_status_id === 1">
          Do you want to Approve or Decline?
        </h4>
        <h4 class="text-center mb-2" v-else>
          Do you want to
          {{ bookingPostData.booking_status_id === 2 ? "Decline" : "Approve" }}?
        </h4>
        <div class="d-flex justify-content-around align-items-center">
          <b-button v-if="bookingPostData.booking_status_id !== 2" pill size="lg" variant="success"
            @click="updateBookingData(2)">
            <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
            <span v-else>Approve</span>
          </b-button>
          <b-button v-if="bookingPostData.booking_status_id !== 3" pill size="lg" variant="danger"
            @click="updateBookingData(3)">
            <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
            <span v-else>Decline</span>
          </b-button>
        </div>
      </b-modal>
    </div>
    <!--Model-->

    <!--Edit_Del_Modal-->
    <div>
      <b-modal v-model="editDelModal" id="modal-scrollable-actions" class="modal-scrollable"
        :size="isAllEdit ? 'lg' : 'sm'" header-class="text-uppercase" :scrollable="isAllEdit && true"
        :centered="!isAllEdit && true" hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            ">
            <h5 class="mb-0" v-if="!isAllEdit">Are you sure to delete?</h5>
            <h5 class="mb-0" v-if="isAllEdit">Edit Service</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>

        <form ref="form" v-if="isAllEdit">
          <b-form-group>
            <!-- Title & Sub_Title -->
            <div class="mb-2">
              <b-row>
                <b-col>
                  <div>
                    <label for="title" class="font-weight-bolder">Title :</label>
                    <b-form-input v-model="postForm.title" id="title" placeholder="Enter Service Title" required>
                    </b-form-input>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="subTitle" class="font-weight-bolder">Sub Title :</label>
                    <b-form-input v-model="postForm.sub_title" id="subTitle" placeholder="Enter Service Sub Title"
                      required>
                    </b-form-input>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Categories -->
            <div class="mb-2">
              <label for="categories" class="font-weight-bolder">Categories :</label>
              <b-form-select v-model="postForm.category_id" required>
                <b-form-select-option disabled value="">Please Select a category</b-form-select-option>
                <b-form-select-option v-for="c in categories" :key="c.id" :value="c.id">{{ c.name
                }}</b-form-select-option>
              </b-form-select>
            </div>

            <!-- Description -->
            <div class="mb-2">
              <label for="des" class="font-weight-bolder">Description :</label>
              <vue-editor v-model="postForm.description"></vue-editor>
            </div>

            <!-- Contact Info -->
            <div class="mb-2">
              <label for="contact-info" class="font-weight-bolder">Contact Info :</label>
              <b-form-input v-model="postForm.contact_info" id="contact-info" placeholder="Enter Contact Info" required>
              </b-form-input>
            </div>

            <!-- Email & Phone -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="email" class="font-weight-bolder">Email :</label>
                  <b-form-input v-model="postForm.email" id="email" type="email" placeholder="example@gmail.com" required>
                  </b-form-input>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="phno" class="font-weight-bolder">Phone :</label>
                  <b-form-input v-model="postForm.phone" id="phno" placeholder="Phone Number" required>
                  </b-form-input>
                </div>
              </b-col>
            </b-row>

            <!-- Latitude & Longitude -->
            <div class="mb-2">
              <b-row>
                <b-col>
                  <div>
                    <label for="lat" class="font-weight-bolder">Latitude :</label>
                    <b-form-input v-model="postForm.lat" id="lat" placeholder="example - 16.768148">
                    </b-form-input>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="lng" class="font-weight-bolder">Longitude :</label>
                    <b-form-input v-model="postForm.lng" id="lng" placeholder="example - 96.174930">
                    </b-form-input>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- Booking_status & Publish_status -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder">Booking Mode :</label>
                  <b-form-checkbox v-model="checked" @change="toggle(checked)" name="check-button" switch>
                  </b-form-checkbox>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder">Publish :</label>
                  <b-form-checkbox v-model="publish" @change="toggle(publish)" name="check-button" switch>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
            <!-- States -->
            <div class="mb-2">
              <label for="states" class="font-weight-bolder">States :</label>
              <b-form-select v-model="postForm.state_id" required>
                <b-form-select-option disabled value="">Please Select a state</b-form-select-option>
                <b-form-select-option v-for="s in states" :key="s.id" :value="s.id">{{ s.name }}</b-form-select-option>
              </b-form-select>
            </div>
            <!-- Opening_time & Closing_time -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="open" class="font-weight-bolder">Opening Time :</label>
                  <b-form-timepicker v-model="openTimeVal" locale="en" @context="openContext"></b-form-timepicker>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="close" class="font-weight-bolder">Closing Time :</label>
                  <b-form-timepicker v-model="closeTimeVal" locale="en" @context="closeContext"></b-form-timepicker>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
        </form>

        <template v-if="isAllEdit">
          <div class="w-100 text-center">
            <b-button variant="primary" @click="updateData('services', postForm)" class="col-6">
              <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
              <span v-else>Update</span>
            </b-button>
          </div>
        </template>

        <template v-if="!isAllEdit">
          <h4 class="text-center mb-2">Delete 1 event from events?</h4>
          <div class="d-flex justify-content-center">
            <!-- cancel btn -->
            <b-button pill variant="primary" @click="editDelModal = false" class="mr-1">
              <span class="text-nowrap">Cancel</span>
            </b-button>

            <!-- delete btn -->
            <b-button pill variant="danger" @click="delServiceData">
              <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
              <span v-else>Delete</span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--edit_del_Modal-->

    <!-- Description Modal -->
    <DescriptionModal :desData="desData" />
    <!-- Description Modal -->
  </div>
</template>
  
<script>
import store from "@/store";
import { ref, reactive, computed, watch, onMounted } from "@vue/composition-api";
import Table from "../components/Table.vue";
import MyLightBox from "../components/MyLightBox.vue";
import DescriptionModal from "../components/DescriptionModal.vue";
import MyPhotoSingleUpload from "../components/MyPhotoSingleUpload.vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { VueEditor } from "vue2-quill-editor";
import { useRouter } from "@core/utils/utils";
import convertTo12HourFormat from "../functions/convert24to12";
import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormTextarea,
  BTabs,
  BTab,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BFormRating,
  BFormTimepicker,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    BTabs,
    BTab,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BFormRating,
    BFormTimepicker,
    VueUploadMultipleImage,
    Table,
    MyLightBox,
    DescriptionModal,
    MyPhotoSingleUpload,
    VueEditor,
  },
  props: ["query"],
  setup(props) {
    const { router } = useRouter();
    const menuFields = [
      { key: "id", label: "#" },
      { key: "name", label: "name" },
      { key: "subtitle", label: "sub title" },
      { key: "price", label: "price" },
      { key: "description", label: "description" },
      { key: "photo_url", label: "photos" },
      { key: "actions", label: "actions" },
    ];
    const cuponFields = [
      { key: "id", label: "#" },
      { key: "code", label: "code" },
      { key: "name", label: "name" },
      { key: "description", label: "description" },
      { key: "actions", label: "actions" },
    ];
    const reviewFields = [
      { key: "id", label: "#" },
      { key: "comment", label: "comment" },
      { key: "rating", label: "rating" },
      { key: "actions", label: "actions" },
    ];
    const bookingFields = [
      { key: "id", label: "#" },
      { key: "booking_date", label: "booking date" },
      { key: "booking_time", label: "booking time" },
      { key: "booking_time_slot", label: "booking time slot" },
      { key: "booking_status_id", label: "booking status" },
      { key: "remark", label: "remark" },
    ];
    const spinner = ref(false);
    const myModal = ref(false);
    const editDelModal = ref(false);
    const isMenu = ref(false);
    const isCupon = ref(false);
    const isReview = ref(false);
    const isBooking = ref(false);
    const isPhoto = ref(false);
    const isEdit = ref(false);
    const isAllEdit = ref(false);
    const checked = ref(true);
    const pendingModal = ref(false);
    const publish = ref(true);
    const openTimeVal = ref(null);
    const closeTimeVal = ref(null);
    const serviceDetails = ref([]);
    const categories = ref([]);
    const updatePhotoArr = ref([]);
    const desData = ref({});
    const serviceId = ref(props.query.id);
    const bookings = ref([]);
    const states = ref([]);
    const photoObj = ref({});
    const menuPostDatas = reactive({
      id: "",
      our_service_id: serviceId.value,
      name: "",
      subtitle: "",
      price: "",
      description: "",
      photo: "",
    });
    const cuponPostDatas = reactive({
      id: "",
      our_service_id: serviceId.value,
      code: "",
      name: "",
      description: "",
    });
    const reviewPostDatas = reactive({
      id: "",
      our_service_id: serviceId.value,
      comment: "",
      rating: "",
    });
    const bookingPostData = ref({});
    const postForm = reactive({
      id: "",
      title: "",
      category_id: "",
      sub_title: "",
      description: "",
      contact_info: "",
      phone: "",
      email: "",
      lat: "",
      lng: "",
      booking_mode: 1,
      status: "publish",
      state_id: "",
      opening_time: "",
      closing_time: "",
      photos: [],
    });

    //Functions
    const toggle = (c = checked.value, p = publish.value) => {
      c ? (postForm.booking_mode = 1) : (postForm.booking_mode = 0);
      p ? (postForm.status = "publish") : (postForm.status = "unpublish");
    };
    // Clear Function
    const clearDatas = () => {
      if (isMenu) {
        isMenu.value = false;
        menuPostDatas.id = "";
        menuPostDatas.price = "";
        menuPostDatas.name = "";
        menuPostDatas.subtitle = "";
        menuPostDatas.description = "";
        menuPostDatas.photo = "";
      }

      if (isCupon) {
        isCupon.value = false;
        cuponPostDatas.id = "";
        cuponPostDatas.code = "";
        cuponPostDatas.name = "";
        cuponPostDatas.description = "";
      }

      if (isReview) {
        isReview.value = false;
        reviewPostDatas.id = "";
        reviewPostDatas.comment = "";
        reviewPostDatas.rating = "";
      }

      if (isBooking) {
        isReview.value = false;
        reviewPostDatas.id = "";
        reviewPostDatas.comment = "";
        reviewPostDatas.rating = "";
      }
    };
    // Edit Function
    const editOriVals = () => {
      postForm.id = serviceDetails.value[0].id;
      postForm.title = serviceDetails.value[0].title;
      postForm.category_id = serviceDetails.value[0].category_id;
      postForm.sub_title = serviceDetails.value[0].sub_title;
      postForm.description = serviceDetails.value[0].description;
      postForm.contact_info = serviceDetails.value[0].contact_info;
      postForm.phone = serviceDetails.value[0].phone;
      postForm.email = serviceDetails.value[0].email;
      postForm.lat = serviceDetails.value[0].lat;
      postForm.lng = serviceDetails.value[0].lng;
      postForm.booking_mode = serviceDetails.value[0].booking_mode;
      postForm.status = serviceDetails.value[0].status;
      postForm.state_id = serviceDetails.value[0].state_id;
      openTimeVal.value = serviceDetails.value[0].opening_time;
      closeTimeVal.value = serviceDetails.value[0].closing_time;
    };
    // Description
    const desDetailsData = (data) => {
      desData.value = data;
    }
    // Time
    const openContext = (ctx) => {
      postForm.opening_time = ctx.value.slice(0, 5);
    };
    const closeContext = (ctx) => {
      postForm.closing_time = ctx.value.slice(0, 5);
    };

    //Datas Fetching
    const fetchServiceDetails = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/fetchDetails", {
          path: "services",
          id: serviceId.value,
        })
        .then((response) => {
          serviceDetails.value = [response.data.data];
          spinner.value = false;
        });
    };
    fetchServiceDetails();
    const fetchServiceBookings = () => {
      store.dispatch("app-mypages/fetchDatas", "bookings").then((response) => {
        bookings.value = response.data.data;
      });
    };
    fetchServiceBookings();
    const fetchCategories = () => {
      store
        .dispatch("app-mypages/fetchDatas", "categories")
        .then((response) => {
          categories.value = response.data.data.filter(ts => ts.category_type === 'service');
        });
    };
    fetchCategories();
    const fetchStates = () => {
      store.dispatch("app-mypages/fetchDatas", "states").then((response) => {
        states.value = response.data.data;
      });
    };
    fetchStates();

    const handleCancel = () => {
      isEdit.value = false;
      if (isMenu) {
        menuPostDatas.id = "";
        menuPostDatas.price = "";
        menuPostDatas.name = "";
        menuPostDatas.subtitle = "";
        menuPostDatas.description = "";
        menuPostDatas.photo = "";
      }

      if (isCupon) {
        cuponPostDatas.id = "";
        cuponPostDatas.code = "";
        cuponPostDatas.name = "";
        cuponPostDatas.description = "";
      }

      if (isReview) {
        reviewPostDatas.id = "";
        reviewPostDatas.comment = "";
        reviewPostDatas.rating = "";
      }

      if (isBooking) {
        reviewPostDatas.id = "";
        reviewPostDatas.comment = "";
        reviewPostDatas.rating = "";
      }
    }

    //Data Posting
    const handleSubmit = (postPath, postDatas) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/postDatas", {
          path: postPath,
          payload: postDatas,
        })
        .then((response) => {
          spinner.value = false;
          postDatas.id = "";
          postDatas.code = "";
          postDatas.name = "";
          postDatas.description = "";
          fetchServiceDetails();
          myModal.value = false;
          isMenu.value = false;
          isCupon.value = false;
          isReview.value = false;
          isBooking.value = false;
        });
    };

    // Photo Upload Update
    const uploadPhoto = (photoId) => {
      menuPostDatas.photo = photoId;
    };
    const uploadUpdatePhoto = (updatePhotoIds) => {
      updatePhotoArr.value = updatePhotoIds;
      postForm.photos = updatePhotoArr.value;
    };
    const updatePhoto = () => {
      spinner.value = true;
      editOriVals();
      store
        .dispatch("app-mypages/updatePhotoArr", {
          path: `services/${serviceDetails.value[0].id}`,
          payload: postForm,
        })
        .then((response) => {
          fetchServiceDetails();
          spinner.value = false;
          myModal.value = false;
          editDelModal.value = false;
          isEdit.value = false;
          isAllEdit.value = false;
          clearDatas();
        });
    };
    // Data Editing
    const editData = (isMenu, isCupon, isReview, item) => {
      isEdit.value = true;
      if (isMenu) {
        menuPostDatas.id = item.id;
        menuPostDatas.price = item.price;
        menuPostDatas.name = item.name;
        menuPostDatas.subtitle = item.subtitle;
        menuPostDatas.description = item.description;
        menuPostDatas.photo = item.photo.id;
        photoObj.value = item.photo;
      }

      if (isCupon) {
        cuponPostDatas.id = item.id;
        cuponPostDatas.code = item.code;
        cuponPostDatas.name = item.name;
        cuponPostDatas.description = item.description;
      }

      if (isReview) {
        reviewPostDatas.id = item.id;
        reviewPostDatas.comment = item.comment;
        reviewPostDatas.rating = item.rating;
      }
    };
    const editServiceData = () => {
      editDelModal.value = true;
      isAllEdit.value = true;

      editOriVals();
    };
    const updateData = (postPath, postDatas) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: postPath,
          payload: postDatas,
        })
        .then((response) => {
          fetchServiceDetails();
          spinner.value = false;
          myModal.value = false;
          editDelModal.value = false;
          isEdit.value = false;
          isAllEdit.value = false;
          clearDatas();
        });
    };

    const pendingBookingData = (postBookingDatas) => {
      bookingPostData.value = postBookingDatas;
    };
    const updateBookingData = (status_id) => {
      spinner.value = true;
      pendingModal.value = false;
      bookingPostData.value.booking_status_id = status_id;
      store
        .dispatch("app-mypages/updateData", {
          path: "bookings",
          payload: bookingPostData.value,
        })
        .then((response) => {
          fetchServiceBookings();
          spinner.value = false;
          // isBooking.value = false;
        });
    };

    // Data Deleting
    const delFunc = (postPath, id) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/delData", { path: postPath, id })
        .then((response) => {
          fetchServiceDetails();
          spinner.value = false;
          myModal.value = false;
          isMenu.value = false;
          isCupon.value = false;
          isReview.value = false;
          isBooking.value = false;
        });
    };
    const delServiceData = () => {
      spinner.value = true;
      editDelModal.value = false;
      store
        .dispatch("app-mypages/delData", {
          path: "services",
          id: serviceDetails.value[0].id,
        })
        .then((response) => {
          spinner.value = false;
          router.go(-1);
        });
    };

    // Close Function
    const close = () => {
      isBooking.value = false;
      pendingModal.value = false;
      myModal.value = false;
      editDelModal.value = false;
      isEdit.value = false;
      // isAllEdit.value = false;
      clearDatas();
    };

    return {
      fetchServiceDetails,
      spinner,
      myModal,
      editDelModal,
      pendingModal,
      isEdit,
      isAllEdit,
      menuFields,
      cuponFields,
      reviewFields,
      bookingFields,
      bookings,
      states,
      photoObj,
      menuPostDatas,
      cuponPostDatas,
      reviewPostDatas,
      bookingPostData,
      isMenu,
      isCupon,
      isReview,
      isBooking,
      isPhoto,
      publish,
      serviceDetails,
      handleSubmit,
      editData,
      updateData,
      pendingBookingData,
      updateBookingData,
      delFunc,
      checked,
      categories,
      postForm,
      editServiceData,
      delServiceData,
      uploadPhoto,
      uploadUpdatePhoto,
      updatePhoto,
      editOriVals,
      toggle,
      openTimeVal,
      openContext,
      closeTimeVal,
      closeContext,
      desDetailsData,
      desData,
      close,
      handleCancel,
      router,
      convertTo12HourFormat,
    };
  },
};
</script>
  
<style scoped>
.photo-con {
  height: 300px;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
<style scoped>
.text-underline {
  text-decoration: underline;
}
</style>